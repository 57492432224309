.Logo {
  width: 12rem;
  margin-left: 2rem;
}

rapi-doc::part(section-navbar) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-search) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-item) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-item):hover {
  background-color: var(--main-blue);
  color: white;
  border-radius: 0;
  border: 0px;
}

rapi-doc::part(section-navbar-active-item) {
  background-color: var(--main-blue);
  color: white;
  border-radius: 0;
  border: 0px;
}

rapi-doc::part(textbox) {
  background-color: white;
  color: black;
}
