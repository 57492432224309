@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Inter", sans-serif;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* Font Sizes */
  --font-11: 1.1rem;
  --font-12: 1.2rem;
  --font-13: 1.3rem;
  --font-135: 1.35rem;
  --font-14: 1.4rem;
  --font-15: 1.5rem;
  --font-16: 1.6rem;
  --font-17: 1.7rem;
  --font-18: 1.8rem;
  --font-20: 2rem;
  --font-22: 2.2rem;
  --font-24: 2.4rem;
  --font-26: 2.6rem;
  --font-28: 2.8rem;
  --font-30: 3rem;
  --font-32: 3.2rem;
  --font-34: 3.4rem;
  --font-36: 3.6rem;
  --font-38: 3.8rem;
  --font-40: 4rem;
  --font-42: 4.2rem;
  --font-45: 4.5rem;
  --font-48: 4.8rem;
  --font-52: 5.2rem;
  --font-70: 7rem;
  --fontv-4: 4vw;
  --fontv-3: 3vw;
  --fontv-35: 3.5vw;
  /* Colors */
  --pure-white: #ffffff;

  --pure-black: #000000;
  --primary-black: #222222;
  --dark-black: #200e32;
  --sec-black: #444444;
  --ter-black: #888888;
  --font-black: #0f1629;

  --font-grey: #3e424a;
  --primary-grey: #767676;
  --sec-grey: #aaaaaa;
  --ter-grey: #bbbbbb;
  --line-grey: #cccccc;
  --lig-grey: #dddddd;
  --quad-grey: #e0e0e0;
  --pent-grey: #f8f8f8;
  --hex-grey: #fafafa;
  --sept-grey: #dbdcdf;
  --deca-grey: #f9fcff;
  --holdingsTable-grey: #768396;
  --oct-grey: #dee1e6;
  --light-grey: #eeeeee;
  --peg-grey: #f3f3f4;
  --light-bg-grey: #fdfdfd;
  --main-grey: #eff2f5;
  --comp-grey: #b4becc;
  --background-grey: #eff2f5;

  --primary-orange: #f36a01;
  --orange: #ee734f;
  --orange-light: #fdeeea;

  --warning-yellow: #ffc107;

  --primary-blue: #222c65;
  --sec-mark-blue: #2e7bff;
  --ter-blue: #0057ff;
  --main-blue: #0141cf;
  --sec-blue: #0052fe;
  --light-blue: #f6f9ff;
  --light-quad-blue: #0052fe2e;
  --peg-blue: #e9f0ff;
  --light-sec-blue: #e7efff;
  --light-ter-blue: #1874d2;
  --link-blue: #2997f9;

  --primary-red: #d70808;
  --loss-red: #e75757;
  --light-red: #d65745;
  --peg-red: #ffeded;
  --peg-red-font: #be3338;
  --sec-red: #ef4136;

  --success-green: #79ea86;
  --primary-green: #009229;
  --profit-green: #00a92f;
  --sec-green: #25e006;
  --border-grey: #dddddd;
  --divider-grey: #bdbec1;
  --peg-green: #d7fdef;
  --peg-green-font: #068e5d;
  --sea-green: #309799;
  --sea-green-light: #e9f4f5;

  --blue-bg-light: #edf2ff;
  --blue-bg-dark: #200e32;
  --bitbns: #9b2ad6;

  --lavender: #7557d4;
  --lavender-light: #eeebfa;
  --orange: #ee734f;
  --orange-light: #fdeeea;
  --sea-green: #309799;
  --sea-green-light: #e9f4f5;
  /* tds colors */
  --tds-light-blue: #69c0ff;
  --tds-blue: #2f82ff;
  --tds-orange: #ff7a45;
  --tds-green: #73d13d;
  --tds-red: #f5222d;
  --tds-purple: #b37feb;
  --tds-dark-purple: #9b51e0;
  /* Coinswitch colors */
  --coinswitch-primary-purple: #514ed8;
  --twitter-blue: #4a99e9;
  /* Gradiants */
  --blue-btn-bg: linear-gradient(90.13deg, #005dfc -7.06%, #0041b1 104.45%);
  --prefill-blue-gradient: linear-gradient(180deg,
      #1800e2 0%,
      rgba(91, 9, 141, 0.98) 100%);
  --prefill-orange-gradient: linear-gradient(180deg, #f33a00 0%, #fa9a29 100%);
  --waiting-list-confirmation-text-gradient: linear-gradient(90.13deg,
      #ff0051 -7.06%,
      #bd00ff 104.45%);

  --lavender-gradient: linear-gradient(to right,
      #7557d4,
      #947ce0,
      #b1a0ea,
      #cfc5f3,
      #eeebfa);
  --orange-gradient: linear-gradient(to right,
      #ee734f,
      #f79275,
      #fdb19b,
      #ffcfc2,
      #fdeeea);
  --sea-green-gradient: linear-gradient(to right,
      #309799,
      #67aeb0,
      #94c5c8,
      #bfdcde,
      #e9f4f5);
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background-color: rgba(230, 230, 230, 0.2);
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(120, 120, 120, 0.3);
  outline: 1px solid slategrey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input,
Button,
select,
textarea {
  font-family: "Inter", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.DisabledBtn {
  opacity: 0.5;
}
.Rapidoc_Logo__2WvAv {
  width: 12rem;
  margin-left: 2rem;
}

rapi-doc::part(section-navbar) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-search) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-item) {
  background-color: white;
  color: black;
}

rapi-doc::part(section-navbar-item):hover {
  background-color: var(--main-blue);
  color: white;
  border-radius: 0;
  border: 0px;
}

rapi-doc::part(section-navbar-active-item) {
  background-color: var(--main-blue);
  color: white;
  border-radius: 0;
  border: 0px;
}

rapi-doc::part(textbox) {
  background-color: white;
  color: black;
}

